import React from 'react';
import { Link } from '@reach/router';
import footerData from '../../footerData.json'

const footer= () => (
  <footer className="footer-light">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Nosotros</h5>
                            <ul>
                                <li><Link to="/nosotros">Acerca de</Link></li>
                                <li><Link to="/galeria">Galería</Link></li>
                                <li><Link to="/menu">Menú</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Sucursales</h5>
                            <ul>
                                {
                                footerData.branches.map((item,index)=>{
                                    return(
                                        <li key={index}><a href={item.link} target="_blank">{item.name}</a></li>
                                    )
                                })
                                }
                                
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Links de Interés</h5>
                            <ul>
                                <li><Link to="/contacto">Contacto</Link></li>
                                <li><Link to="/sucursales">Ubica tu sucursal</Link></li>
                                <li><Link to="/aviso-de-privacidad">Aviso de privacidad</Link></li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Noticias</h5>
                            <p>Suscribete a nuesto boletín de novedades.</p>
                            <form action="#" className="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
                                <div className="col text-center">
                                    <input className="form-control" id="txt_subscribe" name="txt_subscribe" placeholder="enter your email" type="text" /> 
                                    <Link to="" id="btn-subscribe">
                                      <i className="arrow_right bg-color-secondary"></i>
                                    </Link>
                                    <div className="clearfix"></div>
                                </div>
                            </form>
                            <div className="spacer-10"></div>
                            <small>Tu correo está a salvo. No hacemos Spam :).</small>
                        </div>
                    </div>
                </div>
            </div>
            <div className="subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="de-flex">
                                <div className="de-flex-col">
                                    <span onClick={()=> window.open("/", "_self")}>
                                        <span className="copy">{footerData.copyright}</span>
                                    </span>
                                </div>
                                <div className="de-flex-col">
                                    <div className="social-icons">
                                        {
                                            footerData.socialLinks.map((item,key)=>{
                                                return (
                                                    <span key={key} onClick={()=> window.open(item.url, "_self")}><i className={`fa ${item.icon} fa-lg`}></i></span>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
);
export default footer;