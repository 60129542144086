import React from 'react';
import Footer from '../components/footer';
import Header from '../menu/header';
import { createGlobalStyle } from 'styled-components';
import {branches} from '../../footerData.json'

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
`;

const Branches= () => (
<div>
<GlobalStyles/>
  <Header/>
  <section className='container'>
      <section className='jumbotron breadcumb no-bg'>
    <div className='mainbreadcumb'>
      <div className='container'>


  <div className='row'>
          <div className="col-md-12 text-center">
                         <center>
            <img src="/img/sucursales.png"  style={{width:'100%',maxWidth:"800px"}}/>
            </center>
          </div>
        </div>
      </div>
    </div>
  </section>
    <div className="row">
        {
            branches.map((item,index)=>{
                return(
                    <div key={index} className="col-lg-6 col-md-6 col-sm-12 mb-6" style={{marginBottom:'20px'}}>
                        <div className="feature-box f-boxed style-3 text-center" >
                            <div className="text">
                                <h4>{item.name}</h4>
                                <p>{item.address}</p>
                                <a href={item.link} target="_blank" className="btn-main m-auto">Cómo llegar</a>
                            </div>
                        </div>
                    </div>  
                )
            })
        }
  

    </div>
  </section>

  <Footer />
</div>

);
export default Branches;