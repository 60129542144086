import React, { Component } from "react";
import styled from "styled-components";
import Clock from "./Clock";

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

export default class Responsive extends Component {

  constructor(props) {
    super(props);
    this.state = {
        nfts: this.dummyData,
    height: 0
    };
    this.onImgLoad = this.onImgLoad.bind(this);
    }

    onImgLoad({target:img}) {
        let currentHeight = this.state.height;
        if(currentHeight < img.offsetHeight) {
            this.setState({
                height: img.offsetHeight
            })
        }
    }

 render() {
  return (
    <div className='row'>
        <center>
            <img src="/img/historia.png" style={{width:'100%',maxWidth:"800px"}}/>
            </center>
    
        <p>
         
Había una vez un grupo de amigos amantes de la comida, la cerveza y las parrilladas, estos se juntaban todos los jueves a convivir en la ciudad de Guadalajara en el barrio de Talpita, en casa de uno de ellos. Esta casa contaba con un extenso jardín donde había un horno a la leña y un asador, es ahí donde exploraban y hacían comidas diferentes cada semana ya fuera la típica carnita asada, costillas horneadas, discada, tacos de lengua, etc. Conforme los días pasaban el grupo iba creciendo y tuvieron que adoptar un nombre “Los Cerdos Salvajes”.
Pasó el tiempo y comenzó a surgir la idea de llevar lo que se vivía en ese lugar a nivel de restaurante, tratando siempre de mantener la esencia del grupo, como la amistad, parrilladas al aire libre y el amor por la buena comida.
  
            </p>        
            <p>

                Nuestros inicios como restaurante se remontan a finales del 2012 cuando al ver tantos bares y restaurantes que ofertaban la misma idea, una única forma de preparación y salsas iguales en todos lados, a partir de esto es como se comienza la búsqueda de un producto que pudiera ser la contraparte de las llamadas “Alitas”.

            </p>
            <p>
                El 13 de diciembre del 2012 nace Jabalies Costillas & Bar® en la colonia Jardines Alcalde, sin ninguna idea de lo que era un restaurante, solo con una parrilla, una estufa de tres quemadores y un viejo refrigerador que estaba abandonado en el local.
            </p>
            <p>
                
Así es como nos dimos a la tarea de crear un producto que no fuera congelado, que no se tuviera que freír, que las salsas no tuvieran conservadores y que ofrecieran un valor más alto al comensal, así es como nacen nuestras deliciosas costillas de cerdo (Cerdoribs®) que se bañan con alguna de nuestras salsas caseras.
            </p>
            <p>
                
Pasados los años y con un poco más de experiencia se inaugura Cerdos Salvajes el 10 de Septiembre del 2015 y es aquí donde nace nuestra famosa Cerdotabla®. Se comienza a forjar nuestra identidad y cambia completamente nuestra forma de preparar los alimentos, lo que antes estaba enfocado a ser un botanero con venta de cerveza, ahora se convierte en un verdadero restaurante.
            </p>
            <p>
Tras de 7 años de operación, Jabalies Costillas & Bar® cierra su puertas para convertirse en Cerdos Salvajes® es con esto que nace el grupo “CERDOS SALVAJES S.A. de C.V.” comprometidos con la mejora continua y la idea de capacitarnos constantemente para dar lo mejor a nuestros comensales. </p>
    </div>              
    );
}
}