import React, { Component } from "react";
import styled from "styled-components";
import Clock from "./Clock";

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

export default class Responsive extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
        nfts: this.dummyData,
    height: 0
    };
    this.onImgLoad = this.onImgLoad.bind(this);
    }

    onImgLoad({target:img}) {
        let currentHeight = this.state.height;
        if(currentHeight < img.offsetHeight) {
            this.setState({
                height: img.offsetHeight
            })
        }
    }

 render() {
  return (
    <div className='row'>
             <center>
            <img src="/img/concepto.png" style={{width:'100%',maxWidth:"800px"}}/>
            </center>
        
        <p> Nuestro concepto es la comida “Cerda”, grande y abundante. </p>
        <p>
Está diseñada para compartir en familias y en grupos de amigos que quieran darse un gran festín.
        </p>
        <p>Nuestro menú es en su mayoría carne de cerdo. La estrella de la casa es la Cerdotabla® que contiene un poco de todo.</p>
    </div>              
    );
}
}