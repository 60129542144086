import React from 'react';
import emailjs from 'emailjs-com';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Header from '../menu/header';
import {branches} from '../../footerData.json'
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;

const contact= function() {

  function sendEmail(e) {

    const success = document.getElementById("success");
    const button = document.getElementById("buttonsent");
    const failed = document.getElementById("failed");
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_csfdEZiA', e.target, 'user_zu7p2b3lDibMCDutH5hif')
      .then((result) => {
          console.log(result.text);
          success.classList.add('show');
          button.classList.add('show');
          failed.classList.remove('show');
      }, (error) => {
          console.log(error.text);
          failed.classList.add('show');
      });
  }

  return (
  <div>
  <GlobalStyles />
  <Header/>
  <section className='jumbotron breadcumb no-bg'>
    <div className='mainbreadcumb'>
      <div className='container'>


  <div className='row'>
          <div className="col-md-12 text-center">
                         <center>
            <img src="/img/contacto.png"  style={{width:'100%',maxWidth:"800px"}}/>
            </center>
          </div>
        </div>
      </div>
    </div>
  </section>

      <section className='container'>
        
        <div className='row'>
          <div className='col-lg-8 mb-3'>
          <h3>¿Algúna sugerencia?</h3>
            <div className="form-side">
              <form className="formcontact" onSubmit={sendEmail}>
                <input type="text" className="form-control" name="user_name" placeholder="Nombre" required />
                <input type="email" className="form-control" name="user_email" placeholder="Email" required />
                <input type="text" className="form-control" name="user_phone" placeholder="Teléfono/Móvil" required />
                <textarea name="message" className="form-control" placeholder="Mensaje" required />
                <div id='success' className='hide'>Tu mensaje se envió correctamente...</div>
                <div id='failed' className='hide'>Ups, ocurrió un error ...</div>
                <input type='submit' id='buttonsent' value='Enviar' className="btn btn-main color-2" />
              </form>
            </div>
          </div>

          <div className='col-md-4'>
          
          {
            branches.map((item, index)=>{
                return(
                  <div className="padding40 box-rounded mb30">
                    <h3>{item.name}</h3>
                    <address className="s1">
                      <span><i className="id-color fa fa-map-marker fa-lg"></i>{item.name}</span>
                      <span><i className="id-color fa fa-phone fa-lg"></i>{item.phone}</span>
                      <span><i className="id-color fa fa-envelope-o fa-lg"></i><span className='btn'>{item.email}</span></span>
                      <span><i className="id-color fa fa-file-pdf-o fa-lg"></i><a href={item.link} className='btn'>Cómo llegar</a></span>
                    </address>
                  </div>
                )
            })
          }


           

          </div>

        </div>
      </section>
      <Footer />
    </div>
  );
}
export default contact;