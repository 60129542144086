import React, {useState, useEffect} from 'react';
import {isMobile} from 'react-device-detect';
//import { Dimensions } from 'react-native';


import { createGlobalStyle } from 'styled-components';
import HTMLFlipBook from "react-pageflip";

import MENU from '../../menu.json'

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #212428;
    border-bottom: 0;
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-3{
    display: block !important;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-3{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #fff;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
`;
/*

const screen = Dimensions.get("screen");
const btnWidth = screen.width*0.85
const progressWidth = screen.width*0.50*/
const W = 1.77
const Menu= () => {
  const size = useWindowSize();
  const [bookWidth, setBookWidth] = useState(600)
  const [bookHeight, setBookHeight] = useState(1024)
  const [margintop,setMargintop] = useState('100px')
  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    if(isMobile)
    {
      setBookWidth (size.width)
      setBookHeight(size.width*W)
    }
    else
    {
      if(size.width >= 1400){
        setBookWidth (600)
        setBookHeight(1124)
      }
      if(size.width <= 1399){
        setBookWidth (600)
        setBookHeight(1024)
      }
      if(size.width <= 900)
      {
        setBookWidth (400)
        setBookHeight(850)
        setMargintop('50px')
      }
      if(size.width <= 600)
      {
      setBookWidth (size.width)
      setBookHeight(size.width*W)
      }
    }
  },[size])

  return (
    <div>
  <GlobalStyles/>
  <center style={{marginTop:'10px'}}>
    <a target="_blank" href="/" className="btn-main">Regresar</a>
    </center>
    <HTMLFlipBook width={bookWidth} height={bookHeight} showCover={true}>
                      {
                        MENU.map((page,index)=>{
                          let R =<></>
                          switch(page.type){
                            case 'Portada':
                              R =<img className="menuImgBK" src={page.img}/>
                              break;
                            default:
                              R =<></>  
                          }
                          let ITEM = { 
                            ...page,
                            index:index,
                            content:R
                          }
                          return(
                            <Page key={index} {... ITEM} />
                          )
                        })
                      }
    </HTMLFlipBook>
    </div>
  )
};
export default Menu;

const Page = React.forwardRef((props, ref) => {
  console.log(props)
  let {content,backgroundi,index} = props
  return (
    <div className="demoPage" ref={ref} >
      <h1></h1>
      <div className="demoPageContent" style={{background:backgroundi?backgroundi:''}}> 
        {props.img?<img className="menuImgBK" src={props.img}/>:{content}}
      </div>
      <p>Page number: {index}</p>
    </div>
  );
});


// Hook
const useWindowSize = ()=> {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}