import React from 'react';
import Header from '../menu/header';
import Footer from '../components/footer';


const Privacy= () => (
<div>
  <Header/>
  <section className='jumbotron breadcumb no-bg' >
    <div className='mainbreadcumb'>
      <div className='container'>
        <div className='row m-10-hor'>
          <div className='col-12'>
            <h1 className='text-center'>Política de Privacidad</h1>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className='container'>
    
  </section>

  <Footer />
</div>

);
export default Privacy;