import React, { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { header } from 'react-bootstrap';
import { Link } from '@reach/router';
import useOnclickOutside from "react-cool-onclickoutside";


setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = props => (
  <Link 
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);



const Header= function() {
    const [openMenu, setOpenMenu] = React.useState(false);
    
    const closeMenu = () => {
      setOpenMenu(false);
    };
 
    const [showmenu, btn_icon] = useState(false);
    useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
        btn_icon(false);
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
          totop.classList.add("show");
          
        } else {
          header.classList.remove("sticky");
          totop.classList.remove("show");
        } if (window.pageYOffset > sticky) {
          closeMenu();
        }
      });
      return () => {
        window.removeEventListener("scroll", scrollCallBack);
      };
    }, []);
    return (
    <header id="myHeader" className='navbar white'>
     <div className='container'>
       <div className='row w-100-nav'>
          <div className='logo px-0'>
              <div className='navbar-title navbar-item'>
                <NavLink to="/">
                <img
                    src="./logo192.png"
                    className="img-fluid d-block"
                    alt="#"
                  />
                  <img
                    src="./logo192.png"
                    className="img-fluid d-3"
                    alt="#"
                  />
                </NavLink>
              </div>
          </div>
                    
              <BreakpointProvider>
                <Breakpoint l down>
                  {showmenu && 
                  <div className='menu'>
                    <div className='navbar-item'>
                       <NavLink to="/menu">Menu</NavLink>
                    </div>
                    <div className='navbar-item'>
                       <NavLink to="/nosotros">Nosotros</NavLink>
                    </div>
                    <div className='navbar-item'>
                       <NavLink to="/contacto">Contacto</NavLink>
                    </div>
                    <div className='navbar-item'>
                       <NavLink to="/sucursales">Sucursales</NavLink>
                    </div>

                  </div>
                  }
                </Breakpoint>

                <Breakpoint xl>
                  <div className='menu'>
                        <div className='navbar-item'>
                       <NavLink to="/menu">Menu</NavLink>
                    </div>
                    <div className='navbar-item'>
                       <NavLink to="/nosotros">Nosotros</NavLink>
                    </div>
                    <div className='navbar-item'>
                       <NavLink to="/contacto">Contacto</NavLink>
                    </div>
                    <div className='navbar-item'>
                       <NavLink to="/sucursales">Sucursales</NavLink>
                    </div>
                
                  </div>
                </Breakpoint>
              </BreakpointProvider>
              <div className='mainside'>
                <a target="_blank" href="https://api.whatsapp.com/send?phone=5213317729957&app=facebook&entry_point=page_cta&fbclid=IwAR3jrk8wN25kcEdSLXkJ8K9gbkHdK39ujcM0Bsc9hDU8CCX2sA-GMd6ImMI" className="btn-main">Ordena Ahora</a>
              </div>
                  
      </div>
        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>
      </div>     
    </header>
    );
}
export default Header;