import React, {useState, useEffect} from 'react';

import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Header from '../menu/header';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #212428;
    border-bottom: 0;
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-3{
    display: block !important;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-3{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #fff;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
`;

const Homethree= () => {
  const size = useWindowSize();
  const [margintop,setMargintop] = useState('0px')


  useEffect(() => {

  },[size])

  return (
    <div>
      
    <GlobalStyles />
    <Header/>
      <div className='container containerbg01' style={{marginTop:margintop}}>
        <div className='row'>
          <div className='col-lg-12'>
            <div className="content">
              <center style={{paddingBottom:"30px",paddingTop:"150px"}}>
                          <div className='mainside'>
                    <a target="_blank" href="/menu" className="btn-main">Ver Menú</a>
                  </div>
                <div className="content-middle" style={{paddingBottom:"30px",paddingTop:"30px"}}>
                  <img className="logo" src="/img/logo_portada.png" />
                </div>
        
                  <div className='mainside'>
                  <a target="_blank" href="https://api.whatsapp.com/send?phone=5213317729957&app=facebook&entry_point=page_cta&fbclid=IwAR3jrk8wN25kcEdSLXkJ8K9gbkHdK39ujcM0Bsc9hDU8CCX2sA-GMd6ImMI" className="btn-main">Ordena Ahora</a>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
};
export default Homethree;

const Page = React.forwardRef((props, ref) => {
  console.log(props)
  let {content,backgroundi,index} = props
  return (
    <div className="demoPage" ref={ref} >
      <h1></h1>
      <div className="demoPageContent" style={{background:backgroundi?backgroundi:''}}> 
        {props.img?<img className="menuImgBK" src={props.img}/>:{content}}
      </div>
      <p>Page number: {index}</p>
    </div>
  );
});


// Hook
const useWindowSize = ()=> {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}