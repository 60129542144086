import React from 'react';
import ImageGallery from "../components/ImageGallery";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Header from '../menu/header';
import Cerdoilustraciones from "../components/ImageGallery/Cerdoilustraciones.json";
import Influencers from "../components/ImageGallery/Influencers.json";
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;


export default function works() {
return (
<div>

<GlobalStyles />
<Header/>


  <section className='container'>
  <div className='row'>
          <div className="col-md-12 text-center">
                         <center>
            <img src="/img/galeria.png"  style={{width:'100%',maxWidth:"800px"}}/>
            </center>
          </div>
    </div>
    <div className='row'>

    <ImageGallery images={Cerdoilustraciones}/>

    </div>
  </section>

    <section className='container'>
    <div className='row'>

    <ImageGallery images={Influencers}/>

    </div>
  </section>

  <Footer />
</div>
);

}
